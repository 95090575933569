import { TemplateNameType } from './template-name.enum';

interface FeatureConfig {
  name: string;
  path: string;
}

type FeatureByTemplateNameMapping = {
  [key in TemplateNameType]: FeatureConfig;
};

export const FEATURE_TEMPLATE_NAME_MAPPING: FeatureByTemplateNameMapping = {
  [TemplateNameType.vocabulary]: { name: 'vocabulary', path: 'vocabulary' },
  [TemplateNameType.pictures]: { name: 'pictures', path: 'pictures' },
  [TemplateNameType.document]: { name: 'document', path: 'document' },
  [TemplateNameType.video]: { name: 'videos', path: 'videos' },
  [TemplateNameType.iframe]: { name: 'iframe', path: 'iframe' },
  [TemplateNameType.proca_data_quality]: { name: 'proca-data-quality', path: 'proca-data-quality' },
  [TemplateNameType.proca_responsible_persons]: {
    name: 'proca-responsible-persons',
    path: 'proca-responsible-persons',
  },
  [TemplateNameType.properties]: { name: 'properties', path: 'property-catalog' },
  [TemplateNameType.costcenter]: { name: 'costcenter', path: 'property-catalog' },
  [TemplateNameType.geoModule]: { name: 'geography', path: 'geo-module' },
  [TemplateNameType.orgaModule]: { name: 'organization', path: 'orga-module' },
};
