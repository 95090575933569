export enum TemplateNameType {
  vocabulary = 'vocabulary',
  pictures = 'pictures',
  document = 'document',
  video = 'video',
  iframe = 'iframe',
  proca_data_quality = 'proca-data-quality',
  proca_responsible_persons = 'proca-responsible-persons',
  properties = 'properties',
  costcenter = 'costcenter',
  geoModule = 'geo-module',
  orgaModule = 'orga-module',
}
